<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
    }),
    computed: {
      cards () {
        return [
          {
            icon: 'mdi-lock-check',
            title: this.$t('sections.features.cards.1.title'),
            text: this.$t('sections.features.cards.1.text'),
          },
          {
            icon: 'mdi-monitor-cellphone',
            title: this.$t('sections.features.cards.2.title'),
            text: this.$t('sections.features.cards.2.text'),
          },
          {
            icon: 'mdi-monitor-dashboard',
            title: this.$t('sections.features.cards.3.title'),
            text: this.$t('sections.features.cards.3.text'),
          },
          {
            icon: 'mdi-puzzle-outline',
            title: this.$t('sections.features.cards.4.title'),
            text: this.$t('sections.features.cards.4.text'),
          },
        ]
      },
    },
  }
</script>
